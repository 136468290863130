@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Comfortaa&family=Poppins&family=Inter&display=swap');

.App {
  padding: 0;
  margin: 0;
  height: auto;
  width: 100%;
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6{
font-family: 'Comfortaa';
font-style: normal;

}

p{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
/* font-size: 16px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width:760px) {
  .header{
    display: none;
  }
}

@media only screen and (min-width: 768px){
  .header1{
    display: none;
  }
  .bottomnavbardiv{
    display: none;
  }
}
.Apppage{
  overflow: hidden;
}