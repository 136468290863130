.condition{
    max-width:1000px;
    height: auto;
    flex-shrink: 0;
    margin: 0 auto;
    margin-top: -4rem;
    background: #F7F7F7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
}
.condition-details{

    text-align: justify;

}
.condtion-details .condition-detailsp{
    color: #000;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}
.condition-details .condition-detailsh3{
    text-align: center;
    padding: 2rem;
    color: #000;
    font-size: 24px;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
/* ul li{ 
    padding: 4px;
} */
.condition-detailsspan{
    font-weight: bold;
}