/* Donationdetailsformpage.css */

/* Custom background color for the body */
.donationdetailsformcustom-body {
  background: #c7f2ab; /* Replace with your desired background color */
}

/* Form styles */
.donationdetailsform .container {
  overflow: hidden;
}

/* Styles for the back button */
.donationdetailsform .donationdetailsformarrow {
  border: 1px solid black;
  border-radius: 30px;
  padding: 6px 9px;
  width: 30px;
  background: white;
  margin: 10px 0px 0px 16px;
}
.donationdetailsform #donationdetailsformcheckbox:focus{
  box-shadow: none;
  }
  
/* Heading style for the form */
.donationdetailsform .donationdetailsformh6 {
  font-weight: 400;
  font-size: 40px;
  color: #044eaf;
  font-family: Comfortaa;
}

/* Styles for form input fields */
.donationdetailsform #donationdetailsforminp {
  width: 100%;
  margin: 20px 0px;
  background: none;
  border: none;
  border-bottom: 4px solid black;
  outline: none; /* Add this line to remove the outline */
  font-size: 20px;
  font-family: Poppins;
}

/* Styles for the dropdown icon */
.donationdetailsform .polygon-dropdown-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Styles for the dropdown select element (disabled default dropdown icon) */
/* .donationdetailsformdropdown-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
} */

/* Styles for the submit button */
.donationdetailsform #donationdetailsformbtn {
  color: white;
  background: #1b5240;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  width: 161px;
  height: 52px;
  font-size: 25px;
  font-family: Arvo;
}

/* Placeholder color for form input fields */
.donationdetailsform .donationdetailsformform-class input::placeholder {
  color: #000000; /* Color for the placeholder text */
}

/* Styles for the checkbox info text */
.donationdetailsform .donationdetailsformcheckinfo {
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}

/* Media query for screens with max-width 576px */
@media screen and (max-width: 576px) {
  /* Responsive font size for the form heading */
  .donationdetailsform .donationdetailsformh6 {
    font-size: 24px;
  }

  /* Responsive font size for the checkbox info text */
  .donationdetailsform .donationdetailsformcheckinfo {
    font-size: 16px;
  }

  /* Responsive font size for the submit button */
  .donationdetailsform #donationdetailsformbtn {
    font-size: 16px;
  }

  /* Margins for the arrow icon on smaller screens */
  .donationdetailsform #donationdetailsformrrow {
    margin: 0px 15px;
  }

  /* Hide the image on smaller screens */
  .donationdetailsform .fundsimg {
    display: none;
  }
}
