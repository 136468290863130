/* Styling for elements with class .slogancar4-row .slogancard4-3rd */
.slogancar4-row .slogancard4-3rd {
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  width: 100% !important;
  background: none !important;
  height: auto !important;
}

/* Styling for elements with class .slogancar4-row .slogancard4-3rd:hover */
.slogancar4-row .slogancard4-3rd:hover {
  background: none !important;
}

/* Media query for devices with maximum width of 575.98px */
@media (max-width: 575.98px) {
  /* Styling for elements with id #cardpara1 */
  #cardpara1 {
    color: #000;
    font-size: 24px;
  }

  /* Styling for elements with id #cardpara2 */
  #cardpara2 {
    font-size: 18px;
  }

  /* Styling for elements with id #cardpara3 */
  #cardpara3 {
    font-size: 16px;
    color: #000;
  }
}

/* Styling for elements with id #cardpara1 and #cardpara3 */
#cardpara1, #cardpara3 {
  font-family: Poppins;
  font-weight: 700;
  color: #000;
}

/* Styling for elements with id #cardpara2 */
#cardpara2 {
  font-family: 'Comfortaa';
  font-weight: 700;
  color: #000;
}

/* Media query for devices with minimum width of 992px */
@media only screen and (min-width: 992px) {
  /* Styling for elements with class .slogancar4-row .slogancard4-3rd */
  .slogancard4-row .slogancard4-3rd {
    width: 100%;
  }

  /* Styling for elements with class .slogancard4-row */
  .slogancard4-row {
    padding: 0px 40px;
  }
}

/* Media query for devices with minimum width of 768px */
@media only screen and (min-width: 768px) {
  /* Styling for elements with id #cardpara1 */
  #cardpara1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 28px;
  }

  /* Styling for elements with id #cardpara2 */
  #cardpara2 {
    font-family: 'Comfortaa';
    font-weight: 700;
    font-size: 48px;
    line-height: 54px;
    justify-content: center;
    text-align: left;
  }

  /* Styling for elements with class .middle-text-container */
  .middle-text-container {
    display: flex;
    justify-content: center;
  }

  /* Media query for small screens */
  @media (max-width: 576px) {
    .middle-text-container {
      margin: 0; /* Remove the left and right margin for small screens */
    }
  }

  /* Styling for elements with id #cardpara3 */
  #cardpara3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
}

/* slider */
@media (min-width: 992px) {
  /* Styling for elements with class .slogancard4sliding-buttons #slogancard4slider-btn */
  .slogancard4sliding-buttons #slogancard4slider-btn {
    visibility: visible !important;
  }
}

/* Styling for elements with class .slick-prev and .slick-next */
.slick-prev, .slick-next {
  display: none !important;
}

/* Styling for elements with class .slogancard4sliding-buttons #slogancard4slider-btn */
.slogancard4sliding-buttons #slogancard4slider-btn {
  border: none !important;
  color: #000000 !important;
  font-size: 24px !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #FFFFFF !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  display: flex !important;
}

/* Styling for elements with class .slogancard4sliding-buttons */
.slogancard4sliding-buttons {
  display: flex !important;
}

/* Media query for devices with minimum width of 1025px */
@media (min-width:1025px) {
  /* Styling for elements with class .slogancard4slider-container */
  .slogancard4slider-container {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
  }
}
