

@media (max-width: 760px) {
   .home-section{
    display: none;
   }
}
.momeal,.one{
font-weight: 400;
font-size: 18px;
line-height: 27px;

}