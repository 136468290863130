@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Comfortaa&family=Poppins&display=swap');

/* Apply the CSS styles for the circular icon container */
.bottom-icon-container {
    border: 4px solid #D9D9D9;
    background: var(--green, #067749);
    margin-top: -50px;
   
    border-radius: 50%;
  }
  
  /* Center the icon */
  .bottomicon-center {
    display: flex;
    justify-content: center;
  }
  /*use a -for link tag */
  .bottompage a{
    color: #676D75;
    font-family: 'Poppins';
  
    font-style: normal;
    font-weight: 500;
  }
  .bottompage .b-link:hover {
    border-bottom: solid 2px #067A46;
    color: #067A46;
    }
    @media (max-width: 600px) {
        #bottomnav{
            font-size: 12px;
        }
    }
      /*use a -for link tag */
   .bottompage a {

      list-style-type: none;
  
      text-decoration: none;
    }
    