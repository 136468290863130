/* CSS for Containera Component */

/* Styling for the containera-box */
.containera-box {
  background-color: #C7F2AB;
  margin-top: -10px;
}

/* Styling for the containera-wrapper, which contains the two main parts */
.containera-wrapper {
  display: flex;
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding-top: 3rem;
  align-items: center;
}

/* Styling for each item in the first part (containerapart1-list) */
.containerapart1-list {
  display: flex;
  width: 255px;
  height: 81px;
  background: #FFFFFF;
  border-radius: 40px;
  margin-bottom: 3rem;
  margin-top: 1rem;
  background: #FFFFFF;
  border-radius: 40px;
}

/* Styling for each item's content in containerapart1-list */
.containerapart1-list-item {
  display: flex;
  width: 100%;
  margin: 9px;
}

/* Styling for the image in each containerapart1-list item */
.containerapart1-list .containerapart1-img {
  width: 65px;
  height: 65px;
  padding: 11px;
}

/* Styling for the second part (containera-part2) */
.containera-wrapper .containera-part2 {
  width: 57%;
  border-left: 3px solid gray;
}

/* Styling for the text details in containerapart1-list */
.containerapart1-list-details .containerapart1-listp {
  margin: 0;
  margin-top: 22px;
  line-height: 0px;
  font-family: Poopins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

/* Styling for the heading in containerapart1-list */
.containerapart1-list-details .containerapart1-listh3 {
  margin-top: 19px;
  line-height: 0.4rem;
}

/* Styling for the details in the second part */
.containera-part2-details {
  margin: 1rem 2rem;
}

/* Styling for the main heading in the second part */
.containera-part2-details .containera-part2h3 {
  width: 453x;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 45px;
  color: #000000;
}

/* Styling for the paragraph in the second part */
.containera-part2-details .containera-part2p {
  width: 100%;
  text-align: justify;
  margin-top: 2rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  word-spacing: 1px;
}

/* Styling for the 'Join Us' button in the second part */
.containera-part2-details .containerapart2joinbtn {
  font-size: 20px;
  font-family: Arvo;
  background: #067749;
  border-radius: 30px;
  font-weight: 400;
  width: 183px;
  height: 60px;
  line-height: 25px;
  border: none;
  margin-bottom: 4rem;
  color: #FFFFFF;
}

/* Hover styling for the 'Join Us' button */
.containerapart2joinbtn:hover {
  background-color: #FD7E14 !important;
  color: white !important;
}

/* Media queries for responsive design */

/* Media query for large screens (min-width: 1900px) */
@media screen and (min-width: 1900px) { 
  .containera-wrapper .containerapart1-list.right-side {
    float: none;
    margin: 50px 100px;
  }
}

/* Media query for medium and large screens */
@media only screen and (min-width: 992px), screen and (min-width: 768px) {
  .containerapart1-list.right-side {
    float: right;
    margin-right: 2rem;
  }

  .containera-part1 .containera-part1-ul {
    padding-left: 2rem;
  }

  .containera-part2-details .containera-part2h3 {
    line-height: 50px;
  }

  .containera-wrapper .containera-part1 {
    width: 43%;
  }

  .containera-wrapper {
    padding-bottom: 3rem;
  }
}

/* Media query for smaller screens (max-width: 923px) */
@media (max-width: 923px) {
  .containera-wrapper {
    width: 100%;
  }

  .containera-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .containera-wrapper .containera-part1 {
    width: 100%;
    border: none;
    margin: 0 auto;
  }

  .containera-wrapper .containera-part2 {
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px;
  }
}

/* Additional media query for smaller screens (max-width: 575.98px) */
@media (max-width: 575.98px) {
  .join-us-button-containera {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .containera-part1 .containera-part1-ul {
    padding-left: 0px;
  }

  .containera-part2-details .containera-part2h3 {
    font-size: 28px;
    text-align: center;
  }

  .containera-part2-details .containera-part2p {
    font-size: 16px;
    text-align: center;
  }

  .containera-wrapper {
    display: flex;
    flex-direction: column;
  }

  .containera-wrapper .containera-part1 {
    justify-content: center;
    display: flex;
  }

  .containera-wrapper .containera-part2 {
    width: 100%;
    padding: 0px 20px;
  }

  .container-part2-details {
    margin: 0;
  }

  .containera-wrapper {
    padding-top: 2rem;
  }

  .part1-list {
    margin-bottom: 1.5rem;
    margin-top: 0rem;
  }

  .containera-wrapper .containera-part2 {
    border-left: 0px;
  }
}
.containera-part1 .containera-part1-ul {
  list-style: none;
  }