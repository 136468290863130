/* Font imports */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Montserrat:wght@100&display=swap');

/* Styles for middle container cards */
.middle-container-card {
  border-radius: 4px !important;
  background: #F1F1F1 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  text-align: left !important;
  cursor: pointer !important;
}

.middle-container .middle-containercard-body {
  text-align: left;
}

.middle-container .middle-containercard-text,
.middle-containercard-title {
  width: 100%;
  margin: 0px;
}

.middle-containercard-text {
  width: 100% !important;
}

@media (max-width: 1024px) {
  .middle-container-card {
    height: 26rem !important;
  }
}

@media (min-width: 1200px) {
  .middlecardrow {
    margin: 0px 145px !important;
  }
  
  .middle-container-card {
    height: 23rem !important;
    width: 350px !important;
  }
}

.middle-container-card:hover {
  background-color: #D55342 !important;
}

.middle-container-card:hover .middle-containerheading,
.middle-container-card:hover .middle-containercard-text {
  color: white !important;
}

.middle-container-card:hover .middle-containercard-line {
  background-color: white !important;
}

@media (max-width: 768px) {
  .middlecardrow {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.middle-container .middle-containercard-title {
  text-align: left !important;
  font-size: 18px !important;
  font-family: 'Comfortaa' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  text-transform: uppercase !important;
  justify-content: left !important;
  display: flex !important;
  color: #000000 !important;
}

.middle-container .middle-containercard-text {
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #000000;
}

.middle-container .middle-containercard-line {
  width: 50px !important;
  height: 2px !important;
  background-color: #000 !important;
  align-self: flex-start !important;
  position: relative;
}

.middle-container .middle-containercard-text {
  margin-top: 10px !important;
  margin-bottom: 0 !important; /* Optional: Remove any bottom margin if needed */
}

#member_but:hover {
  background: orange;
  box-shadow: none;
}

#member_but {
  border: none;
  color: white;
  width: 250px;
  height: 55px;
  top: 100px;
  font-size: x-large;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  background: #067749;
  border: 1px solid #000000;
  border-radius: 30px;
}

/* Middle container slider styles */
.middle-containerslider-container {
  position: relative;
  margin: 0px 40px;
}

.middle-containerslider-buttons {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.middle-containerslider-button:focus {
  box-shadow: none;
  border-color: none;
}

@media (min-width: 992px) {
  .middle-containerslider-button {
    visibility: hidden !important;
  }
}

.middle-containerslider-button:first-child {
  margin-left: -40px !important;
}

.middle-containerslider-button:last-child {
  margin-right: -40px !important;
}

/* Slider button styles */
.middle-containerslider-button {
  background-color: transparent !important;
  border: none !important;
  color: #000000 !important;
  font-size: 24px !important;
  cursor: pointer !important;
  outline: none !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 2px solid #000000 !important;
  transition: background-color 0.3s !important;
  position: relative !important;
  z-index: 2 !important;
}

.middle-containerslider-button:hover {
  background: #ECECEC;
}

@media (min-width: 1025px) {
  .middle-containerslider-container {
    margin: 0 auto;
    max-width: 80%;
    position: relative;
  }
}

/* Additional styles for MiddleCard component */
.middle-container {
  background: #ECECEC !important;
}

.work-headerh1 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 600;
  font-size: 42px;
}

.work-headerp {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 27px !important;
}

@media (max-width: 768px) {
  .middle-container-card {
    width: 100% !important;
  }
  .work-headerh1 {
    font-size: 20px !important;
  }
  .work-headerp {
    font-size: 17px !important;
  }
}
