/* HelpCard.css */

/* Styles for the container of the help card */
.help_container {
  background: #C7F2AB;
  padding: 0 50px;
}

/* Styles for the 'One Hand For Others' heading */
.p_heading {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
}

/* Styles for the main heading */
.h1_heading {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
}

/* Styles for the low-level heading */
.p_low_heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

/* Styles for the inner card title */
.inner_card .helpcard-title {
  font-family: 'Comfortaa' !important;
  margin: 0px;
  font-size: 24px;
  text-align: left;
  font-weight: 400;
}

/* Styles for the inner card text */
.inner_card .helpcard-text {
  margin: 0px;
  font-family: 'Poppins' !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  width: 100% !important;
  text-align: left;
}

/* Styles for the 'Help them' button */
.but_help {
  background-color: orange;
  border-radius: 15px;
  border: none;
  height: 51px;
  width: 160px;
  color: #fff;
  font-size: x-large;
  box-shadow: 2px 2px 2px 0 rgb(119, 112, 112);
}

/* Styles for the hover state of the 'Help them' button */
.but_help:hover {
  background-color: #067A46 ;
}

/* Styles for the inner cards */
.inner_card {
  position: relative;
  width: 350px !important;
  height: 300px !important;
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  font-family: 'Comfortaa';
}

/* Styles for the arrow icon on the cards */
.help_container .arrow {
  height: 25px;
  width: 25px;
  background-color: white;
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Styles for the color lines on the cards */
.color-line1,
.color-line2,
.color-line3 {
  height: 7px;
  width: 40%;
  position: absolute;
  bottom: 0;
  left: 40px;
  background-color: white;
}

/* Styles for the arrow position on card 1, 2, and 3 */
.help_container .arrow1,
.help_container .arrow2,
.help_container .arrow3 {
  top: 260px;
  right: 10px;
}

/* Styles for the container of the cards */
.cards_help {
  display: flex;
  top: 20px;
  gap: 10px;
}

/* Styles for the inner card 1 */
.cards_help .inner-hcard1 {
  background-color: #ED4B4B;
  color: white;
}

/* Styles for the inner card 2 */
.cards_help .inner-hcard2 {
  background-color: #067749;
  color: white;
}

/* Styles for the inner card 3 */
.cards_help .inner-hcard3 {
  background-color: #FD7E14;
  color: white;
}

/* Media query for larger screens */
@media (min-width: 992px) {
  .help_container {
    margin-right: 10px;
  }
}

/* Media query for small and medium-sized screens in portrait orientation */
@media (max-width: 768px) {
  .help_container {
    flex-direction: column;
    justify-content: center;
    height: auto;
  }

  .inner_card {
    width: 100% !important;
  }

  .help_left,
  .help_right {
    position: static;
    margin: 0 auto;
    text-align: center;
  }

  .cards_help {
    flex-direction: column !important;
    align-items: center !important;
    right: initial !important;
  }

  .color-line1,
  .color-line2,
  .color-line3 {
    position: absolute;
    margin-top: 80px;
    left: 50px;
    top: initial;
  }

  /* Adjust the main heading size for smaller screens */
  .h1_heading {
    font-size: 25px;
    font-family: 600;
  }
}

/* Media query for medium-sized screens in portrait orientation */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .cards_help {
    flex-direction: row !important;
    align-items: center !important;
    right: initial !important;
  }
}

/* Media query for screens with max-width 1024px */
@media (max-width: 1024px) {
  .help_container {
    height: auto !important;
    padding: 20px !important;
  }

  .help_left,
  .help_right {
    position: static;
    margin: 0 auto !important;
    text-align: center !important;
    left: initial !important;
    bottom: initial !important;
  }

  .inner_card {
    width: 100% !important;
    max-width: 400px !important;
  }
}
