/* Adding some styles to the entire page */
.formpage1 {
  background-color: #C7F2AB !important;
}

.formpage1 .container {
  overflow: hidden;
}

.formpage1 .formpage1arrowimage{
  border: 1px solid black;
  border-radius: 30px;
  padding: 6px 9px;
  width: 30px;
  background: white;
  margin: 10px 0px 0px 16px;
}

/* Form title styles */
.formpage1 .formpage1h6 {
  font-weight: 400;
  font-size: 40px;
  color: #044EAF;
  font-family: Comfortaa;
}

/* Form input styles */
.formpage1 #formpage1inp {
  width: 100%;
  margin: 20px 0px;
  background: none;
  border: none;
  border-bottom: 4px solid black;
  outline: none; /* Add this line to remove the outline */
  font-size: 20px;
  font-family: Poppins;
}

/* Submit button styles */
.formpage1 #formpage1btn1,
#formpage1btn2 {
  color: white;
  background: #1B5240;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  width: 161px;
  height: 52px;
  font-size: 25px;
  font-family: Arvo;
}

.formpage1 .formpage1form-class input::placeholder {
  color: #000000; /* Color for the placeholder text */
}

/* Checkbox */
.formpage1 #formpage1checkbox1:focus {
  box-shadow: none;
}

.formpage1  #formpage1checkbox2:focus {
  box-shadow: none;
}

/* Checkbox info styles */
.formpage1 .formpage1checkinfo {
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}

/* Heading style for adding food image section */
.formpage1 .formpage1heading4 {
  display: block;
  font-family: Arvo;
  font-weight: 400;
  font-size: 25px;
}

/* Not visible in large screen */
.formpage1  #formpage1heading3 {
  display: none;
}

/* Styling for the dashed circle to add food image */
.formpage1 .formpage1card {
  border: 1px solid black !important;
  border-radius: 30px;
  
}

.formpage1 .formpage1dashed-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px dashed black;
}

/* Styling for the plus button in the dashed circle */
.formpage1 .formpage1plus-btn {
  font-weight: bolder ;
  font-size: 86px;
  font-family: Arvo;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: none;
  border: none;    
  color: black;
}

/* Slider */
.slider-rail {
  color: gray !important;
}

.slider-thumb {
  color: #044EAF !important;
}

.slider-thumb {
  box-shadow: none !important;
}

/* Media queries for responsive design */
@media screen and (min-width: 768px) {
  /* Hide the 2nd submit button in large screen view */
  .formpage1  #formpage1btn2 {
    display: none;
  }

  /* Hide the 2nd checkbox in large screen view */
  .formpage1  #formpage1checkbox2 {
    display: none;
  }

  .formpage1 .formpage1card {
    width: 282px !important;
    height: 171px !important;
    box-shadow: none !important;
  }
}

@media screen and (max-width: 576px) {
  /* Not visible in small screen */
  .formpage1 .formpage1heading4 {
    display: none;
  }

  /* Visible in small screen */
  .formpage1  #formpage1heading3 {
    display: block;
    color: #067A46;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  /* Hide the 1st submit button in small screen view */
  .formpage1 #formpage1btn1 {
    display: none;
  }

  /* Hide the 1st checkbox in small screen view */
  .formpage1  #formpage1checkbox1 {
    display: none;
  }

  .formpage1 .formpage1h6 {
    font-size: 24px;
  }

  /* Display the 2nd checkbox in small screen view */
  /* #checkbox2 {
    display: flex;
  } */
  .formpage1  #formpage1inp,.formpage1min,.formpage1max{
    font-size: 15px;
  }
  .formpage1 .formpage1checkinfo{
    font-size: 16px;
  }
  .formpage1 #formpagebtn2{
    font-size: 16px;
  }
  .formpage1 #formpage1row{
    margin: 0px 15px;
  }
  .formpage1 .formpage1dashed-circle{
    width: 50px;
    height: 50px
  }
  .formpage1 .formpage1card{
    width: 100% !important;
  }
  .formpage1  .formpage1plus-btn{
    font-size: 16px;
    font-family: Arvo;
  }
  .formpage1 .formpage1flex-container {
    justify-content: center;
    display: flex;
    text-align: center;
    gap: 15px;
  }
}
