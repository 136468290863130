/* Styling for DonationDetails.js component */

/* Styling for the container that holds the donation cards */
.Donation-container {
    background-color: #EEEEEE;
  }
  
  /* Styling for each donation card */
  .donar-card.card {
    background-color: #EEEEEE;
    border: none;
    margin-top: 2rem;
    box-shadow: none !important;
  }
  
  /* Styling for the amount of donation */
  .data-amount {
    color: #FD7E14; /* Orange color for the donation amount */
    margin: 0;
  }
  
  /* Styling for the time of the donation */
  .data-time {
    margin: 0;
    color: #D90000; /* Red color for the time of the donation */
  }
  
  /* Styling for the comment of the donation */
  .data-comment {
    margin: 0;
  }
  
  /* Styling for the container that holds the share cause icons */
  .share-cause-Container {
    margin-top: 3rem;
    background-color: #EEEEEE;
  }
  
  /* Styling for the list of share cause icons */
  .share-cause-list ul {
    display: flex;
    list-style-type: none;
  }
