/* Fromleft.css */

/* Styles for the caption and buttons */
.donation-caption {
  /* max-width:65%;
    padding:58px; */

  font-style: "Arvo";
}

/* Styles for the cause description */
.cause-description {
  font-size: 21px;
  font-family: sans-serif;
  font-weight: 400;
}

/* Media query for screens with min-width 782px */
@media (min-width: 782px) {
  .cause-description {
    width: 375px;
  }

  /* Styles for the first half of the caption on larger screens */
  .donation-caption .first-half {
    font-size: 43px;
    line-height: 45px;
    font-weight: 500;
    letter-spacing: -3px;
  }

  /* Styles for the second half of the caption on larger screens */
  .donation-caption .second-half {
    font-size: 58px;
    color: #1b5240;
    font-weight: 800;
    font-size: 52px;
    letter-spacing: -4px;
    position: relative;
    display: inline-block;
  }
}

/* Media query for screens with min-width 1025px */
@media (min-width: 1025px) {
  .cause-description {
    width: 375px;
  }

  /* Align the caption to the left on larger screens */
  .donation-caption {
    text-align: left;
  }

  /* Styles for the first half of the caption on larger screens */
  .donation-caption .first-half {
    font-size: 43px;
    line-height: 45px;
    font-weight: 500;
    letter-spacing: -3px;
  }

  /* Styles for the second half of the caption on larger screens */
  .donation-caption .second-half {
    font-size: 58px;
    color: #1b5240;
    font-weight: bolder;
    line-height: 70px;
    letter-spacing: -4px;
    position: relative;
    display: inline-block;
  }

  /* Underline effect for the second half of the caption on larger screens */
  .underline {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    height: 14px;
    z-index: -1;
    background-color: #a9a9a9;
    border: 2px solid #a9a9a9;
    box-sizing: border-box;
  }
}

/* Styles for the active state of buttons when clicked */
.donation-caption .donate-buttons .fromleftbtn-1:active {
  background: #067a46;
}

.donation-caption .donate-buttons .fromleftbtn-2:active {
  background: #fd7e14;
}

/* Styles for the hover state of buttons */
.donate-buttons .fromleftbtn-1:hover {
  background: #067a46;
  box-shadow: none;
  border: none;
}

/* Styles for the 'Give Food' button */
.donate-buttons .fromleftbtn-1 {
  border-radius: 50px;
  border: none;
  height: 50px;
  color: #ffffff;
  width: 165px;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
  background: #067a46;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

/* Styles for the 'Donate' button */
.donate-buttons .fromleftbtn-2 {
  border-radius: 30px;
  height: 50px;
  width: 165px;
  font-size: 20px;
  color: black;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Media query for screens with max-width 768px */
@media (max-width: 768px) {
  .donate-buttons .fromleftbtn-1,
  .donate-buttons .fromleftbtn-2 {
    height: 50px;
    width: 105px;
    font-size: 15px;
    border-radius: 30px;
    font-weight: 700;
  }

  /* Styles for the first half of the caption on smaller screens */
  .donation-caption .first-half {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
  }

  /* Styles for the second half of the caption on smaller screens */
  .donation-caption .second-half {
    font-weight: 800;
    font-size: 40px;
    color: #1b5240;
  }
}

/* Media query for screens with max-width 280px */
@media (max-width: 280px) {
  /* Adjust the width of the button container on smaller screens */
  .fromleftcolbtns {
    width: 110px !important;
  }
}

/* Styles for the button container */
.donate-buttons {
  margin-top: 40px;
}

/* Styles for the hover state of the 'Donate' button */
.donate-buttons .fromleftbtn-2:hover {
  background-color: #fd7e14;
  color: #ffffff;
  box-shadow: none;
  border: none;
}

/* Media query for screens with max-width 768px */
@media (max-width: 768px) {
  /* Adjust the margin of the caption on smaller screens */
  .donation-caption {
    margin: 0px 30px;
  }
}
